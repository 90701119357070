import GraphqlGateway from "../../../gateway/graphql";
import { getAllCategoryTypeQuery } from "../../../gateway/graphql/queries/orders/getContainerTypes";
import { graphConfig } from "../../../setting/graphConfig";

const getAllCategoryTypeData = async (): Promise<any> => {
    const graphqlGateway = GraphqlGateway.createAPIConnection(graphConfig());
    const query = getAllCategoryTypeQuery();
    return graphqlGateway.doGraphqlRequest(query, {Type: '', Path: ''});
};

export default {
    getAllCategoryTypeData
};

